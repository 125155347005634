(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name lib.directive:warn
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
     <example module="lib">
       <file name="index.html">
        <errors></errors>
       </file>
     </example>
   *
   */
  angular
    .module('lib')
    .directive('warn', warn);

  function warn() {
    return {
      restrict: 'E',
      scope: {
        errors: '=',
        match: '='
      },
      templateUrl: 'lib/warn-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: WarnCtrl,
      bindToController: true,
      link: function(scope, elm, attrs) {
        console.log('attrs', attrs);
        scope.$watch('errors', function (x) {
          console.log('yeap', attrs.errors, x);
          vm.message = 'dfdksfndsk';
        });
      }
    };
  }

  function WarnCtrl() {
    var vm = this;

    vm.message = 'This is a message';

    console.log('got these errors', vm.errors);
    console.log('matching on %s', vm.match);
  }
}());
